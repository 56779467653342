import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['wrappingPrice'];

  change = (event) => {
    const wrappingRequested = event.currentTarget;
    const index = wrappingRequested.selectedIndex;
    const price = wrappingRequested.options[index].dataset.price;
    this.wrappingPriceTarget.value = price;
  }
}
