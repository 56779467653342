import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { delay: Number, frame: String }

  connect() {
    this.timer = setTimeout(() => this.#reload(), this.delayValue)
  }

  disconnect() {
    clearTimeout(this.timer)
  }

  #reload() {
    const option = { action: 'replace' }
    if (this.hasFrameValue) {
      option['frame'] = this.frameValue
    }
    Turbo.visit(location.href, option)
  }
}
