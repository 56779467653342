import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['textAppendee'];

  appendText = (event) => {
    event.preventDefault();
    const appendingText = event.currentTarget.dataset.value;
    const existsAppendText = this.textAppendeeTarget.value.includes(appendingText);

    this.textAppendeeTarget.value = existsAppendText
      ? this.textAppendeeTarget.value.replace(appendingText, '')
      : this.textAppendeeTarget.value + appendingText;
    this.textAppendeeTarget.dispatchEvent(new Event('change'))
    this.textAppendeeTarget.dispatchEvent(new Event('input'))
  };
}
