import { onConnect } from '@sonicgarden/onconnect'
import { isEqual } from '../libs/form_data'

export function initOnce () {
  onConnect('.js-form-dirty input, .js-form-dirty textarea, .js-form-dirty select', (el) => {
    const dirty = new InputDirty(el)
    dirty.init()

    return () => {
      dirty.destroy()
    }
  })
}

class InputDirty {
  constructor(el) {
    this.el = el
  }

  init() {
    this.initialValue = this.#currentValue()
    this.el.addEventListener('change', this.#handleChange)
    this.el.addEventListener('input', this.#handleChange)
  }

  destroy() {
    this.initialValue = null
    this.el.removeEventListener('change', this.#handleChange)
    this.el.removeEventListener('input', this.#handleChange)
  }

  #isDirty() {
    return this.#currentValue() !== this.initialValue
  }

  #handleChange = () => {
    for (const el of this.#targetElements) {
      el.classList.toggle('is-dirty', this.#isDirty())
    }
  }

  #currentValue() {
    switch (this.el.tagName) {
      case 'INPUT':
        switch (this.el.type) {
          case 'checkbox':
          case 'radio':
            return this.el.checked
          default:
            return this.el.value
        }
      case 'SELECT':
      case 'TEXTAREA':
      default:
        return this.el.value
    }
  }

  get #targetElements() {
    if (this.el.tomselect) {
      return [this.el.nextElementSibling]
    } else if (this.el.type === 'radio') {
      return this.#sameNameRadioButtons()
    } else {
      return [this.el]
    }
  }

  #sameNameRadioButtons() {
    if (!this.el.name) {
      return [this.el]
    }

    const form = this.el.closest('form')
    if (!form) {
      return [this.el]
    }

    const radioButtons = form.querySelectorAll(`input[type=radio][name="${this.el.name}"]`)
    return Array.from(radioButtons)
  }
}
