import { Modal } from 'bootstrap'
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['modal', 'spinnerTemplate', 'frame']

  connect () {
    this.modal = new Modal(this.modalTarget)
    this.modalTarget.addEventListener('hidden.bs.modal', this._handleHidden)
    this._renderSpinner()
  }

  disconnect () {
    // NOTE: モーダルから画面遷移してブラウザバックで戻ってきたときにモーダルが表示されっぱなしとならないように
    // 同じ理由で modal には fade をつけていない。hide が完了する前に dispose しないようにするため
    this.modal.hide()
    this.modal.dispose()
    this.modalTarget.removeEventListener('hidden.bs.modal', this._handleHidden)
  }

  show () {
    this.modal.show()
  }

  hide () {
    this.modal.hide()
  }

  submitEnd (e) {
    const { target, detail: { success } } = e
    if (success && !target.dataset.turboModalKeep) {
      this.hide()
    }
  }

  _handleHidden = () => {
    this._renderSpinner()
  }

  _renderSpinner () {
    this.frameTarget.innerHTML = ''

    const spinner = this.spinnerTemplateTarget.content.cloneNode(true)
    this.frameTarget.append(spinner)
  }
}
