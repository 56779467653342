import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['field'];

  run = (event) => {
    event.preventDefault();
    this.fieldTargets.forEach((field) => {
      field.value = null;
      field.tomselect && field.tomselect.clear();
    });
  };
}
