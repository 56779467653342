import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['field'];

  run = (event) => {
    const category = event.currentTarget.dataset.setDefaultCategory;
    this.fieldTargets.forEach((field) => {
      if (field.dataset.setDefaultCategory !== category) {
        return;
      }
      const value = field.dataset.defaultValue;
      field.value = value;
      field.tomselect && field.tomselect.setValue([value]);
    });
  };
}
