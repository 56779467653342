import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    currentId: Number,
    searchSelector: String,
    direction: String
  }

  connect() {
    const currentElement = document.querySelector(`${this.searchSelectorValue}[data-search-id="${this.currentIdValue}"]`);
    const targetElement = this.directionValue == 'previous' ? currentElement.previousElementSibling : currentElement.nextElementSibling;
    if (targetElement) {
      this.element.href = this.element.href.toString().replace(currentElement.dataset.searchId, targetElement.dataset.searchId);
    } else {
      this.element.remove()
    }
  }
}
