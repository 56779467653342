import * as autosize from './autosize'
import * as turboFrameFlash from './turbo_frame_flash'
import * as nonTurboModal from './non_turbo_modal'
import * as formDirty from './form_dirty'

autosize.initOnce()
formDirty.initOnce()

document.addEventListener('turbo:load', () => {
  turboFrameFlash.init()
})

document.addEventListener('turbo:before-cache', () => {
  turboFrameFlash.destroy()
  nonTurboModal.destroy()
})
