import { onConnect } from '@sonicgarden/onconnect'
import autosize from 'autosize'

export function initOnce () {
  onConnect('textarea', (el) => {
    autosize(el)

    return () => {
      autosize.destroy(el)
    }
  })
}
