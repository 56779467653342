export function init() {
  document.addEventListener('turbo:frame-render', handleFrameRender)
}

export function destroy() {
  document.removeEventListener('turbo:frame-render', handleFrameRender)
}

function handleFrameRender(event) {
  const json = event.detail.fetchResponse.header('X-Flash-Messages')
  if (!json) return

  const flash = document.querySelector('#js-flash')
  if (!flash) return

  const messages = JSON.parse(json)
  for (const message of messages) {
    flash.insertAdjacentHTML('beforeend', message)
  }
}
