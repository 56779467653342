import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['searchField', 'form'];

  clear = (event) => {
    event.preventDefault();
    this.searchFieldTarget.value = '';
    this.formTarget.submit();
  };
}
