import { Controller } from '@hotwired/stimulus';
import TomSelect from 'tom-select';

export default class extends Controller {
  connect() {
    this.initTomSelect();
  }

  disconnect() {
    this.tomSelect.destroy();
  }

  initTomSelect = () => {
    const config = {
      plugins: this.element.multiple
        ? ['remove_button', 'clear_button']
        : ['clear_button'],
      render: {
        no_results: () => {
          return `<div class="no-results">候補が見つかりません</div>`;
        },
      },
    };
    this.tomSelect = new TomSelect(this.element, config);
  };
}
