import { Modal } from 'bootstrap';

export const destroy = () => {
  // MEMO: turbo-modal-targetはstimulusコントローラーで制御しているので除外
  document
    .querySelectorAll('.modal.show:not([data-turbo-modal-target])')
    .forEach((element) => {
      const modal = Modal.getInstance(element);
      modal && modal.hide();
    });
};
