import { Controller } from '@hotwired/stimulus';
import autosize from 'autosize'

export default class extends Controller {
  static targets = ['type', 'content', 'contentWrapper'];

  toggle = (event) => {
    const type = event.currentTarget.value;
    const content = type === 'none' ? '' : this.element.dataset.content;
    this.contentTarget.value = content;
    if (type === 'none') {
      this.contentWrapperTarget.classList.add('d-none');
    } else {
      this.contentWrapperTarget.classList.remove('d-none');
      this.contentTarget.readOnly = (type === 'fixed');
    }
    autosize.update(this.contentTarget);
  }
}
