import { Application } from "@hotwired/stimulus"

import '@sonicgarden/auto-submit-form-element'

const application = Application.start()

// Configure Stimulus development experience
application.debug = false
window.Stimulus   = application

export { application }
