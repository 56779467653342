import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.element.addEventListener("keydown", this.preventEnterSubmit)
  }

  disconnect() {
    this.element.removeEventListener("keydown", this.preventEnterSubmit)
  }

  preventEnterSubmit(event) {
    if (event.key === "Enter" && event.target.tagName !== "TEXTAREA") {
      event.preventDefault()
    }
  }
}
