import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['input', 'select']

  selectTargetConnected(element) {
    this.#fill(element)
  }

  fill(ev) {
    const select = ev.currentTarget
    this.#fill(select)
  }

  #fill(select) {
    const selectedOption = select.selectedOptions[0]
    for (const el of this.inputTargets) {
      this.#fillInput(el, selectedOption)
    }
  }

  #fillInput(el, selectedOption) {
    const key = el.dataset.fillValueKey
    const value = selectedOption ? selectedOption.dataset[key] : ''
    const oldValue = el.value
    el.value = value || ''
    if (value !== oldValue) {
      // NOTE: autosize に反応させるため
      el.dispatchEvent(new CustomEvent('input'))
    }
  }
}
