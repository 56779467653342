import { Controller } from '@hotwired/stimulus';
import TomSelect from 'tom-select';

const SEARCH_KEY = 'q[name_or_model_number_cont]';

export default class extends Controller {
  connect() {
    this.initTomSelect();
  }

  disconnect() {
    this.tomSelect.destroy();
  }

  initTomSelect = () => {
    this.tomSelect = new TomSelect(this.element, {
      valueField: 'value',
      labelField: 'label',
      searchField: 'label',
      plugins: ['virtual_scroll'],
      maxOptions: 1000,
      maxItems: 1,

      firstUrl: function (keyword) {
        const q = keyword
          ? `?${SEARCH_KEY}=${encodeURIComponent(keyword)}`
          : '';
        return '/product_variants' + q;
      },
      load: function (keyword, callback) {
        const url = this.getUrl(keyword);
        fetch(url)
          .then((response) => response.json())
          .then((json) => {
            if (json.next_page) {
              const params = new URLSearchParams({ page: json.next_page });
              if (keyword) {
                params.append(SEARCH_KEY, encodeURIComponent(keyword));
              }
              const next_url = '/product_variants?' + params.toString();
              this.setNextUrl(keyword || '', next_url);
            }
            // 追加ローディングされるとスクロールはトップに戻るのを防ぐ
            // SEE: https://github.com/orchidjs/tom-select/issues/556#issuecomment-1919066054
            const _scrollToOption = this.scrollToOption;
            this.scrollToOption = () => {};
            callback(json.data);
            this.scrollToOption = _scrollToOption;
          })
          .catch((e) => {
            callback();
          });
      },
      render: {
        loading_more: function (data, escape) {
          return `<div class="loading-more-results py-2 d-flex align-items-center"><div class="spinner"></div> 候補をローディング中... </div>`;
        },
        no_more_results: function (data, escape) {
          return `<div class="no-more-results py-2">これ以上候補はありません</div>`;
        },
      },
      onFocus: function () {
        // keyword入力されないとloadされないので、focus時にloadする
        this.load();
      },
    });
  };
}
